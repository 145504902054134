var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"}),_c('template',{slot:"content"},[_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Procesar productos")])]),_c('v-spacer'),_c('v-toolbar-title')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ingredientes")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.inputItems,"headers":_vm.inputHeaders},scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('td',{staticStyle:{"width":"100px"}},[_vm._v("Cantidad")]),_c('td',[_vm._v("Producto")]),_c('td')]},proxy:true},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.name)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.remove(_vm.props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_c('inventory-item-select',{ref:"inputItemField",on:{"input":_vm.onInputItemSelected},model:{value:(_vm.inputSelectedItem),callback:function ($$v) {_vm.inputSelectedItem=$$v},expression:"inputSelectedItem"}})],1),_c('td',[_c('v-text-field',{ref:"inputQuantityField",attrs:{"label":"Cantidad","type":"number"},on:{"focus":function($event){return $event.target.select()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addInputItem.apply(null, arguments)}},model:{value:(_vm.inputItemQuantity),callback:function ($$v) {_vm.inputItemQuantity=$$v},expression:"inputItemQuantity"}})],1),_c('td',[_c('v-btn',{ref:"addButton",attrs:{"text":"","icon":"","color":"primary","disabled":_vm.inputItemQuantity == 0 || !_vm.inputSelectedItem},on:{"click":_vm.addInputItem}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Resultados")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.outputProducts,"loading":_vm.isLoading,"headers":_vm.outputHeaders},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.product.name))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_c('product-select',{ref:"outputProductField",attrs:{"products":_vm.products},on:{"input":_vm.onOutputProductSelected},model:{value:(_vm.outputSelectedProduct),callback:function ($$v) {_vm.outputSelectedProduct=$$v},expression:"outputSelectedProduct"}})],1),_c('td',[_c('v-text-field',{ref:"outputQuantityField",attrs:{"label":"Cantidad","type":"number"},on:{"focus":function($event){return $event.target.select()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addOutputProduct.apply(null, arguments)}},model:{value:(_vm.outputQuantityField),callback:function ($$v) {_vm.outputQuantityField=$$v},expression:"outputQuantityField"}})],1),_c('td',[_c('v-btn',{ref:"addButton",attrs:{"text":"","icon":"","color":"primary","disabled":!_vm.outputSelectedProduct || _vm.outputQuantityField <= 0},on:{"click":_vm.addOutputProduct}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveOutputSelected(item)}}},[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.onProcessSelected}},[_vm._v("Procesar")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }