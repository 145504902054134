<template>
    <template-layout>
        <template slot="drawers">
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Procesar productos</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-4" icon @click="showFiltersDrawer = !showFiltersDrawer" v-on="on"><v-icon>mdi-tune</v-icon></v-btn>
                            </template>
                            <span>Filtrar</span>
                        </v-tooltip> -->
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="6">
                        <v-card>
                            <v-card-title>Ingredientes</v-card-title>

                            <v-card-text>
                                <v-data-table
                                    hide-default-footer
                                    hide-default-header
                                    :items="inputItems"
                                    :headers="inputHeaders"
                                    class="elevation-0"
                                >
                                    <template v-slot:headers>
                                        <td style="width: 100px;">Cantidad</td>
                                        <td>Producto</td>
                                        <td></td>
                                    </template>

                                    <template v-slot:item.item="{item}">
                                        {{ item.item.name }}
                                    </template>

                                    <template v-slot:item.quantity="{item}">
                                        {{ item.quantity }}
                                    </template>

                                    <template v-slot:item.actions="{item}">
                                        <v-btn text icon @click="remove(props.item)"><v-icon small>mdi-close</v-icon></v-btn>
                                    </template>

                                    <template v-slot:body.append>
                                        <tr>
                                            <td>
                                                <inventory-item-select
                                                    ref="inputItemField"
                                                    v-model="inputSelectedItem"
                                                    @input="onInputItemSelected"
                                                />
                                            </td>

                                            <td>
                                                <v-text-field
                                                    ref="inputQuantityField"
                                                    label="Cantidad"
                                                    v-model="inputItemQuantity"
                                                    @focus="$event.target.select()"
                                                    type="number"
                                                    @keydown.enter="addInputItem"
                                                ></v-text-field>
                                            </td>

                                            <td>
                                                <v-btn text icon
                                                    ref="addButton"
                                                    color="primary"
                                                    @click="addInputItem"
                                                    :disabled="inputItemQuantity == 0 || !inputSelectedItem"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card>
                            <v-card-title>Resultados</v-card-title>

                            <v-card-text>
                                <v-data-table
                                    hide-default-footer
                                    hide-default-header
                                    :items="outputProducts"
                                    :loading="isLoading"
                                    :headers="outputHeaders"
                                >
                                    <template v-slot:item.product="{ item }">
                                        {{ item.product.name | capitalize }}
                                    </template>

                                    <template v-slot:item.quantity="{ item }">
                                        {{ item.quantity }}
                                    </template>

                                    <template v-slot:body.append>
                                        <tr>
                                            <td>
                                                <product-select
                                                    ref="outputProductField"
                                                    v-model="outputSelectedProduct"
                                                    :products="products"
                                                    @input="onOutputProductSelected"
                                                />
                                            </td>

                                            <td>
                                                <v-text-field
                                                    ref="outputQuantityField"
                                                    label="Cantidad"
                                                    v-model="outputQuantityField"
                                                    @focus="$event.target.select()"
                                                    type="number"
                                                    @keydown.enter="addOutputProduct"
                                                />
                                            </td>

                                            <td>
                                                <v-btn text icon
                                                    ref="addButton"
                                                    color="primary"
                                                    @click="addOutputProduct"
                                                    :disabled="!outputSelectedProduct || outputQuantityField <= 0"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                        <v-btn icon @click.stop="onRemoveOutputSelected(item)">
                                            <v-icon>mdi-play</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-btn dark color="primary" @click="onProcessSelected">Procesar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';
    import InventoryItemSelect from '@/components/Production/InventoryItemSelect';
    import ProductSelect from '@/components/Product/ProductSelect';

    export default {
        components: {
            TemplateLayout,
            InventoryItemSelect,
            ProductSelect
        },

        data() {
            return {
                inputSelectedItem: null,
                inputItemQuantity: null,
                outputSelectedProduct: null,
                outputQuantityField: null,

                inputItems: [],
                outputProducts: [],

                inputHeaders: [
                    { text: 'Resultado', value: 'item' },
                    { text: 'Cantidad', value: 'quantity' },
                    { text: '', value: 'actions' }
                ],

                outputHeaders: [
                    { text: 'Producto', value: 'product' },
                    { text: 'Cantidad', value: 'quantity' },
                    { text: '', value: 'actions' }
                ]
            };
        },

        computed: {
            products() {
                return this.$store.getters['products/getAll']();
            },

            inventoryItems() {
                return this.$store.getters['inventory/getAll']();
            }
        },

        watch: {
        },

        async mounted() {
            await this.$store.dispatch('products/fetchAll');
            await this.$store.dispatch('inventory/fetchAll');
        },

        methods: {
            onInputItemSelected() {
                this.$refs.inputQuantityField.focus();
            },

            onOutputProductSelected() {
                this.$refs.outputQuantityField.focus();
            },

            getInputItem(id) {
                for(let p of this.inputItems) {
                    if(p.item === id) {
                        return p;
                    }
                }

                return null;
            },

            addInputItem() {
                if(!this.inputSelectedItem || this.inputItemQuantity <= 0) {
                    return;
                }

                this.inputItems.push({
                    quantity: parseFloat(this.inputItemQuantity),
                    item: this.inputSelectedItem
                });

                // reset
                this.inputItemQuantity = null;
                this.inputSelectedItem = null;

                this.$nextTick(() => this.$refs['inputItemField'].focus());
            },

            removeInputItem(item) {
                let idx = this.items.indexOf(item);
                this.items.splice(idx, 1);

                this.$emit('input', this.items);
            },

            addOutputProduct() {
                this.outputProducts.push({
                    quantity: parseFloat(this.outputQuantityField),
                    product: this.outputSelectedProduct
                });

                // reset
                this.outputQuantityField = null;
                this.outputSelectedProduct = null;

                this.$nextTick(() => this.$refs['outputProductField'].focus());
            },

            async onProcessSelected() {
                try {
                    let data = {
                        input: this.inputItems.map(i => {
                            return {
                                item: i.item.id,
                                quantity: i.quantity
                            };
                        }),
                        output: this.outputProducts.map(o => {
                            return {
                                product: o.product.id,
                                quantity: o.quantity
                            };
                        })
                    };

                    await this.$store.dispatch('process/process', data);

                    this.$router.push({ name: 'inventoryMovementsList' }).catch(err => {});
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
